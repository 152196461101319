// An example collection of font data with additional metadata,
// in this case “color.”
var customFont = {
  'Playfair Display': { weight: 400 },
  'Playfair Display': { weight: 700 },
  'Thasadith': { weight: 400 },
  'Thasadith': { weight: 700 }
};

var observers = [];

// Make one observer for each font,
// by iterating over the data we already have
Object.keys(customFont).forEach(function(family) {
  var data = customFont[family];
  var obs = new FontFaceObserver(family, data);
  observers.push(obs.load());
});

Promise.all(observers)
  .then(function(fonts) {
    fonts.forEach(function(font) {
      console.log(font.family + ' ' + font.weight + ' ' + 'loaded');

      // Map the result of the Promise back to our existing data,
      // to get the other properties we need.
      console.log(customFont[font.family].style);
    });
  })
  .catch(function(err) {
    console.warn('Some critical fonts are not available:', err);
  });

$(document).ready(function() {

  // Menu

  $('.main-nav-toggle').click(function(){
    $('body:not(.home) .hdr').toggleClass('fixed');
    $('.main-nav.lg-nav')
      .toggleClass('hidden')
      .toggleClass('block')
      .toggleClass('xl:flex')
      .toggleClass('nav-expanded')
    ;
    $('.main-nav.sm-nav')
      .toggleClass('hidden')
      .toggleClass('block')
      .toggleClass('sm:flex')
      .toggleClass('nav-expanded')
    ;
    $('.main-nav-toggle')
      .toggleClass('nav-open')
    ;
    $('.main-nav-toggle svg').toggleClass('hidden').toggleClass('block');
    $('.hdr-contact,.n-home').toggleClass('hidden');
  });

  // Snipcart

  Snipcart.api.configure('shipping_same_as_billing', false);

  $('.snipcart-checkout a')
    .html('<svg class="icon-cart"><use xlink:href="/assets/img/sprites.svg#icon-cart"></use></svg>')
    .addClass('snipcart-checkout')
  ;
  $('li.snipcart-checkout')
    .addClass('cart-li')
    .removeClass('snipcart-checkout')
  ;

  Snipcart.subscribe('cart.ready', function (cart) {
    if (cart.order && cart.order.items.length > 0) {
      $('.snipcart-summary').show();
    }
  });

  Snipcart.subscribe('item.added', function() {
    $('.snipcart-summary').show();
  });

  Snipcart.subscribe('plan.added', function() {
    $('.snipcart-summary').show();
  });

  Snipcart.subscribe('item.removed', function() {
    if(Snipcart.api.items.count() == 0){
      $('.snipcart-summary').hide();
      // $('form').trigger('reset');
    }
  });

  Snipcart.subscribe('plan.removed', function() {
    if(Snipcart.api.plans.count() == 0){
      $('.snipcart-summary').hide();
      // $('form').trigger('reset');
    }
  });

  Snipcart.subscribe('cart.opened', function() {
    var html = $("#cart-content-text").html();
    $(html).insertBefore($("#snipcart-step-content-shipping-address"));
  });

  Snipcart.subscribe('order.completed', function (order) {
    //it means the order contains 1 or more plans
    if(order.items[0].paymentSchedule.intervalCount){
      var url      = window.location.href,
          arr      = url.split('/'),
          category = '';
      if ($('body').hasClass('has-categories')) {
        category = arr[4] + '/';
      }
      var redirect = '/subscription-information/' + category + order.token;
      window.location.href = redirect;
    }
  });

  Snipcart.execute('config', 'show_continue_shopping', true);

  //  // Open links in new windows/tabs

  $('[rel="external"]').click( function() {
    window.open($(this).attr('href') );
    return false;
  });

  // Home

  if($('body').hasClass('home')) {

    $('.hero').slick({
      dots: false,
      arrows: false,
      infinite: true,
      speed: 1000,
      fade: true,
      cssEase: 'linear',
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 8000,
    });

  }

  // FAQs

  $('.faq dd').hide();
  $('dl.faq dt').click(function(){
    $('.faq dd').hide();
    $(this).next('dd').slideToggle();
  });
  if($('body').hasClass('faq')) {
    if(window.location.hash) {
      var hash = window.location.hash;
      $(hash).next('dd').show();
    }
  }

  // Shop

  function categories() {
    var $checkboxes = [];
    $.each($("[name='categories']:checked"), function(){
      $checkboxes.push($(this).val());
    });
    var $categories = $checkboxes.join(', ');
    return $categories;
  }

  if ($('#sub_for').length) {
    $('#sub_for').on('change', function(){
      $('#button_order').data('item-custom2-value',$(this).val());
      $('.xmas').toggleClass('hidden');
      if($(this).val()!='Myself') {
        $('.message-wrap').removeClass('hidden');
        $('#self').text(' loved one');
      } else {
        $('.message-wrap').addClass('hidden');
        $('#self').text('self');
      }
    });
  }

  if ($('#sub_term_upfront').length) {

    var $totalPrice = parseFloat($('#button_order').attr('data-item-price'));

    $('#sub_type').on('change', function(){
      $('#button_sub,#button_order,.upfront,.monthly,#delivered, .has-categories .pill-group, fieldset.addons').toggleClass('hidden');

      if($('body').hasClass('funko-pop-vinyl')) {
        if($(this).val()=='By the month') {
          $('.subcategories').addClass('hidden');
          $('.form-details').removeClass('hidden');
        }
      }
    });

    $('#sub_term_upfront,#sub_term_monthly').on('change', function(event){
      var $term      = $(this).val(),
          $term_text = $(this).find(":selected").attr('data-term'),
          $agePrice  = $('#age_range').find(":selected").attr('data-term-'+$term);

      if ($term > 3) $term = 3;

      $('#initialPrice').text($agePrice);

      $('#subTerm,#delivered span').text($term_text);
      if(event.target.id=='sub_term_upfront') {
        $('#button_order').data('item-custom1-value',$term_text);
      } else {
        $('#button_sub').data('item-payment-interval-count',$term);
      }
    });

    String.prototype.replaceArray = function(find, replace) {
      var replaceString = this;
      for (var i = 0; i < find.length; i++) {
        replaceString = replaceString.replace(find[i], replace);
      }
      return replaceString;
    };

    $('#sub_term_monthly').on('change', function(){
      var $ogName  = $('#button_sub').data('item-name'),
          $periods = ['Monthly','Bi-monthly','Quarterly'],
          $period  = $(this).find(":selected").attr('data-period'),
          $updated = $ogName.replaceArray($periods, $period);
      $('#button_sub').data('item-name',$updated);
      console.log($('#button_sub').data('item-name'));
    });

    $('#age_range').on('change', function(){
      $('#button_order').data('item-custom3-value',$(this).val());
      if($(this).val()!='Adult') {
        $('#difficulty').addClass('hidden');
      } else {
        $('#difficulty').removeClass('hidden');
      }
    });

    // Hide these ones initially
    $('#cat_dinosaurs, #cat_harrypotter, #cat_rickmorty').parent('.pill-wrap').addClass('hidden');

    $('#difficulty').on('change', function(){

      $('#cat_dinosaurs, #cat_harrypotter, #cat_rickmorty').parent('.pill-wrap').removeClass('hidden');
      $('#button_order').data('item-custom5-value',$(this).val());

      if($(this).val()=='Expert (2000+ pieces)') {
        $('#cat_space').parent('.pill-wrap').addClass('hidden');
      } else {
        $('#cat_space').parent('.pill-wrap').removeClass('hidden');
      }

      if($(this).val()=='Easy (100 pieces)' || $(this).val()=='Expert (2000+ pieces)') {
        $('#cat_dinosaurs, #cat_harrypotter, #cat_rickmorty').parent('.pill-wrap').addClass('hidden');
      } else {
        $('#cat_dinosaurs, #cat_harrypotter, #cat_rickmorty').parent('.pill-wrap').removeClass('hidden');
      }

      if($(this).val()=='Hard (1000 pieces)') {
        $('#cat_disney').parent('.pill-wrap').removeClass('hidden');
      } else {
        $('#cat_disney').parent('.pill-wrap').addClass('hidden');
      }

    });
    $('#firstname').on('change', function(){
      $('#button_order').data('item-custom6-value',$(this).val());
    });
    $('#lastname').on('change', function(){
      $('#button_order').data('item-custom7-value',$(this).val());
    });
    $('#age').on('change', function(){
      $('#button_order').data('item-custom8-value',$(this).val());
    });
    $('#gender').on('change', function(){
      $('#button_order').data('item-custom9-value',$(this).val());
    });
    $('#info').on('change', function(){
      $('#button_order').data('item-custom10-value',$(this).val());
    });
    $('#message').on('change', function(){
      $('#button_order').data('item-custom11-value',$(this).val());
    });
    $('.gift-vouchers #voucher_type').on('change', function(){
      window.location.href = '/shop/subscriptions/gift-vouchers/' + $(this).val();
    });
    $('.renew-subscription #voucher_type').on('change', function(){
      window.location.href = '/renew-subscription/' + $(this).val();
    });
    $('#xmas').on('change',function(){
      $('#button_order').data('item-custom12-value',$(this).val());
    });

    $('#button_order').on('click', function(){
      $(this).data('item-custom4-value',categories());
      console.log($(this).data('item-id'));
    });

    // initial variables set up
    var sub_type = $('#sub_type').val(),
        sub_term = '';
    if (sub_type == 'By the month') {
      sub_term = $('#sub_term_monthly').val();
    }
    else {
      sub_term = $('#sub_term_upfront').val();
    }
    var age = $('#age_range').val(),
        diff = $('#difficulty').val();

    // calculate and fill values
    function calc_cart(sub_type, sub_term, age, diff) {

      var value = 0,
          term = '',
          st = sub_term;

      if ((sub_type == 'By the month') && (sub_term == '2')) {
        st = "1";
      }

      if (age == 'Adult') {
        value = window.cart_m[age][diff][sub_type][st];
      }
      else {
        value = window.cart_m[age][sub_type][st];
      }

      if (sub_type == 'By the month') {
        if (sub_term == '1') {
          term = 'every month'
        }
        else {
          term = 'every ' + sub_term + ' months'
        }
      } else {
        if (sub_term == '1') {
          term = 'per month for 1 month'
        }
        else {
          term = 'per month for ' + sub_term + ' months'
        }
      }

      if($('#cat_disney').is(':checked')) {
        value = value + 10;
      }
      var decimals = parseFloat(Math.round(value * 100) / 100).toFixed(2),
          currentID   = $('#button_order').data('item-id'),
          id          = currentID.substring(0, currentID.indexOf('_'));
          id          = id + '_' + value;
          console.log(id);

      $('#initialPrice').html(decimals);
      $('#subTerm').html(term);
      $('.snipcart-add-item')
        .data('item-price',decimals)
        .data('item-id',id);
      // console.log('Price: '+$('#button_order').data('item-price'));
    }

    // events tracking
    $('body').on('change','#sub_type',function() {
      sub_type = $(this).val();
      if (sub_type == 'By the month') {
        $('div.upfront').addClass('hidden');
        $('div.monthly').removeClass('hidden');
        sub_term = $('#sub_term_monthly').val();
      }
      else {
        $('div.monthly').addClass('hidden');
        $('div.upfront').removeClass('hidden');
        sub_term = $('#sub_term_upfront').val();
      }
      calc_cart(sub_type, sub_term, age, diff);
    });

    $('body').on('change','#sub_term_upfront',function() {
      sub_term = $(this).val();
      calc_cart(sub_type, sub_term, age, diff);
      if(!$('body').hasClass('gift-vouchers')){
        updateAddons();
      }
    });

    $('body').on('change','#sub_term_monthly',function() {
      sub_term = $(this).val();
      calc_cart(sub_type, sub_term, age, diff);
    });

    $('body').on('change','#age_range',function() {
      age = $(this).val();
      if (age == 'Adult') {
        $('#difficulty_block').show();
      }
      else {
        $('#difficulty_block').hide();
      }
      calc_cart(sub_type, sub_term, age, diff);
    });

    $('body').on('change','#difficulty',function() {
      diff = $(this).val();
      calc_cart(sub_type, sub_term, age, diff);
    });

    $('body').on('change','#cat_disney',function() {
      calc_cart(sub_type, sub_term, age, diff);
    });

    calc_cart(sub_type, sub_term, age, diff);

  } // end if price has length

  // Main image swapping

  $('.prod-img-thumbs img').click(function(){
    var target = $(this).data('target');
    $('.prod-img-thumbs img')
      .removeClass('opacity-100')
      .addClass('opacity-50')
    ;
    $(this)
      .removeClass('opacity-50')
      .addClass('opacity-100')
    ;
    $('.prod-img-wrap span')
      .addClass('opacity-0')
      .removeClass('opacity-100')
      .removeClass('z-10')
    ;
    $(target)
      .removeClass('opacity-0')
      .addClass('opacity-100')
      .addClass('z-10')
    ;
  });

  // Subscription info page

  $('#shipping').click(function(){
    $('.subs-shipping-fields').toggleClass('hidden');
  });

  $('#gift').click(function(){
    if($('body').hasClass('subscription-information-custom')) {
      $('.subs-personalised')
        .insertBefore('.pill-group')
        .toggleClass('hidden');
    } else {
      $('.subs-gift-fields').toggleClass('hidden');
    }
    if($(this).val()!='Yes') {
      $('#self').text('self');
    } else {
      $('#self').text(' loved one');
    }
    $('.xmas').toggleClass('hidden');
  });

  if($('body').is('[class^="subscription-information"]')) {
    $('[name=categories]').on('change',function(){
      $('#categories').val(categories());
    });
  }

  if($('body').hasClass('subscription-information-custom')) {
    $('.subs-gift-fields').insertBefore('.subs-shipping-fields');
  }

  // Gift info page

  if($('body').hasClass('claim-your-gift-voucher')) {

    $('#initial').on('input', function(){
      var initial = $(this).val();
      code = initial.substr(0, initial.length - 2);
      item = initial.substr(initial.length - 1);
      $('#item').val(item);
      $('#code').val(code);
    });

    $('.gift-code-form').submit(function() {
      $('#initial').prop('disabled', true);;
    });

  }

  // Manage subscription page

  $('[name=sub_action]').on('change', function(){
    if($(this).val()=='Pause or cancel a recurring subscription') {
      $('.subscription-pause-term').removeClass('hidden');
    } else {
      $('.subscription-pause-term').addClass('hidden');
    }
  });

  // Renew

  if($('body').hasClass('renew-subscription')) {

    $('#sub_for').on('change', function(){
      $('.renew-message ').toggleClass('hidden');
    });
    $('#order_num').on('change', function(){
      $('#button_order').data('item-custom6-value',$(this).val());
    });

  }

  // Funko pop

  $('[name=funkopop_categories]').on('change', function(){
    var genre = $(this).val(),
        id = $('#select-wrap-'+ genre);
    id.toggleClass('hidden');
    $('.subcategories').addClass('hidden');
    if($('[name=funkopop_categories]:checked').length > 0) {
      $('.form-details,.subcategories').removeClass('hidden');
    }
  });

  // Offers

  $('[href=#tc]').leanModal({ top : 200, closeButton: '.modal-close' });

  // Add-ons

  function convertFloat( num ){
    var value = parseInt( parseFloat(num) * 100 ) / 100;
    return value;
  }

  function updateAddons() {
    var checked   = [],
        addons      = '',
        addon_price = 0
        boxes       = $('.pill-input'),
        total       = $('#base_price').val(),
        currentID   = $('.pay-button').data('item-id'),
        id          = currentID.substring(0, currentID.indexOf('-')),
        term        = $('#sub_term_upfront').length ? convertFloat($('#sub_term_upfront').val()) : 1;
        base        = convertFloat($('#base_price').val() * term);

    $('.addon-check:checked').each(function () {
      checked.push($(this).val());
      addon_price += convertFloat( $(this).data('price') );
    });

    var updated = addon_price + base,
        addons  = checked.join(', ');
        updated = updated.toFixed(2);

    $('.pay-button').data('price',updated);
    $('#initialPrice').html(updated);
    $('.pay-button')
      .data('item-id', id + '-' + updated)
      .data('item-price',updated)
      .data('item-custom13-value',addons);
  }

  if($('body').hasClass('addon-page')) {

    // Update categories

    $('[name=categories]').on('change',function(){
      var boxes = $('[name=categories]'),
          checked = [];
      for(var i=0; boxes[i]; ++i){
        if(boxes[i].checked){
          checked.push(boxes[i].value);
        }
      }
      var categories = checked.join(', ');
      $('.pay-button').data('item-custom11-value',categories)
    });

    // Update prices and add-ons

    $('.addon-check').on('change',function(){

      updateAddons();

    });

  } // end Add-ons

  // Gift hampers

  if($('body').hasClass('gift-hampers')) {

    $('#puzzle_type').on('change', function(){
      var id    = '#'+$(this).find(':selected').data('option'),
          price = convertFloat($(this).val());
      $('.addon-check').prop('checked', false);
      $('#base_price').val(price);
      $('#initialPrice').html(price.toFixed(2));

      $('.pill-container').addClass('hidden');
      $(id).removeClass('hidden');
      $(id).addClass('grid');

      if(id=='#pills_2440' || id=='#pills_2562') {
        $('.pills-fieldset').addClass('hidden');
      } else {
        $('.pills-fieldset').removeClass('hidden');
      }
      $('.pay-button').data('item-custom14-value',$(this).find(':selected').text());
    });

    $('.pill-input,.addon-check').on('change',function(){

      var genres_checked = $('.pill-input:checked').length,
          addons_checked = $('.addon-check:checked').length,
          puzzle_type = $('#puzzle_type').find(':selected').val();

      if(puzzle_type=='64') {
        if(addons_checked > 0) {
          $('button.pay-button').prop('disabled',false);
        } else {
          $('button.pay-button').prop('disabled',true);
        }
      } else {
        if(genres_checked > 0 && addons_checked > 0) {
          $('button.pay-button').prop('disabled',false);
        } else {
          $('button.pay-button').prop('disabled',true);
        }
      }


    });

  } // end gift hampers

  if($('body').hasClass('gift-hampers-wine-spirits')) {
    $('.pill-input,.addon-check').on('change',function(){
      var genres_checked = $('.pill-input:checked').length,
          addons_checked = $('.addon-check:checked').length;
          confirm_checked = $('#confirm:checked').length;
      if(genres_checked > 0 && addons_checked > 0 && confirm_checked > 0) {
        $('button.pay-button').prop('disabled',false);
      } else {
        $('button.pay-button').prop('disabled',true);
      }
    });
  }

});

// Isotope for sorting products by pieces

// $(window).load(function(){

//   var filters = {},
//       largestInt = 9007199254740992,
//       $grid = $('.shop-items').isotope({
//         itemSelector: '.shop-item',
//         layoutMode: 'fitRows',
//         filter: checkIfFilterApplies,
//         getSortData: {
//           name: '.name',
//           price: '.price'
//         }
//       });


//   function checkIfFilterApplies(){
//     var self = $(this),
//         match = true,
//         data = self.data();
//     for ( var prop in filters ) {
//       match = (filters[prop].min <= data[prop] && filters[prop].max >= data[prop]);
//       if (!match) return false;
//     }
//     return match;
//   }

//   $('.filters-button-group').on( 'click', '.pill', function() {
//     var $this = $(this);
//     // get group key
//     var $buttonGroup = $this.parents('.filters-button-group');
//     var filterGroup = $buttonGroup.data('filter-group');
//     // set filter for group
//     filters[ filterGroup ] = {
//       min:+$this.data('filter-min') || -largestInt,
//       max:+$this.data('filter-max') ||  largestInt};
//     $grid.isotope();
//   });

//   // change is-checked class on buttons
//   $('.filters-button-group').each( function( i, buttonGroup ) {
//     var $buttonGroup = $( buttonGroup );
//     $buttonGroup.on( 'click', 'button', function() {
//       $buttonGroup.find('.is-checked').removeClass('is-checked');
//       $( this ).addClass('is-checked');
//     });
//   });


//   // bind sort button click
//   $('.sort-button-group').on( 'click', 'button', function() {

//     /* Get the element name to sort */
//     var sortValue = $(this).attr('data-sort-value');

//     /* Get the sorting direction: asc||desc */
//     var direction = $(this).attr('data-sort-direction');

//     /* convert it to a boolean */
//     var isAscending = (direction == 'asc');
//     var newDirection = (isAscending) ? 'desc' : 'asc';

//     /* pass it to isotope */
//     $grid.isotope({ sortBy: sortValue, sortAscending: isAscending });

//     $(this).attr('data-sort-direction', newDirection);

//   });

//   // Only show filters after page loads

//   $('.filter-pieces,.sort-button-group').removeClass('hidden');

// });

// Drift – image zoom

if($('body').hasClass('product-page')) {

  var zoomImgs      = document.querySelectorAll('.zoom-img'),
      paneContainer = document.querySelector('.product-detail');

  zoomImgs.forEach(img => {
    new Drift(img, {
      paneContainer: paneContainer,
      inlinePane: false,
      zoomFactor: 2
    });
  });

}
